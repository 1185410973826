import { gsap } from "gsap/all";

class Modal {
  constructor(modal) {
    this.modal = modal;
    this.closeButton = this.modal.querySelector(".close-button");
    this.timeline = gsap.timeline({ paused: true });
  }

  init = () => {
    this.modal.addEventListener("click", this.close);
    this.closeButton.addEventListener("click", this.close);

    this.timeline.fromTo(
      this.modal,
      { opacity: 0, pointerEvents: "none" },
      { opacity: 1, duration: 0.5, pointerEvents: "all", ease: "power2.inOut" }
    );
    this.timeline.fromTo(
      this.modal.querySelector(".modal-content"),
      { y: 100 },
      { y: 0, duration: 0.5, ease: "power2.out" },
      "-=0.3"
    );
    this.modal.classList.remove("hidden");
  };

  open = () => {
    this.modal.classList.add("open");
    this.timeline.play();
  };

  justClose = () => {
    this.timeline.reverse();
    this.modal.classList.remove("open");
  };

  close = (e) => {
    if (
      !e.currentTarget.classList.contains("close-button") &&
      e.target !== this.modal
    )
      return;
    // remove hash from url
    window.history.pushState(
      "",
      document.title,
      window.location.href.split("#")[0]
    );

    this.timeline.reverse();
    this.modal.classList.remove("open");
  };
}

export default Modal;
