import axios from "axios";

class SignIn {
  constructor(form) {
    this.form = form;
  }

  init = () => {
    this.form.addEventListener("submit", this.submit);
  };

  submit = async (e) => {
    e.preventDefault();
    this.submitLoadingToggle(true);
    const data = new FormData(this.form);

    const { data: resData } =
      (await axios
        .post("", data, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
          },
        })
        .catch((err) => this.errorHandler(err))) || {};

    this.submitLoadingToggle(false);

    if (resData) {
      window.location.href = resData.returnUrl;
    }
  };

  submitLoadingToggle = (isLoading) => {
    const submitBtn = this.form.querySelector("button[type=submit]");
    if (isLoading) {
      submitBtn.setAttribute("disabled", true);
      submitBtn.innerText = "Loading...";
    } else {
      submitBtn.removeAttribute("disabled");
      submitBtn.innerText = "Sign In";
    }
  };

  errorHandler = (err) => {
    // remove error message if it exists
    const errorMsg = this.form.querySelector(".error-msg");
    if (errorMsg) {
      errorMsg.remove();
    }
    // add error message
    const errorMsgEl = document.createElement("div");
    errorMsgEl.classList.add(
      "error-msg",
      "bg-red",
      "bg-opacity-20",
      "border",
      "border-red",
      "text-red",
      "px-4",
      "py-3",
      "rounded",
      "relative",
      "mb-4"
    );

    errorMsgEl.innerText = err.response.data.message;
    this.form.querySelector("h2").insertAdjacentElement("afterEnd", errorMsgEl);
  };
}

export default SignIn;
