import axios from "axios";

class Forms {
  constructor() {
    this.forms = document.querySelectorAll("form") || [];
  }

  init = async () => {
    const { data: csrfData } = await axios.get("/dynamic/csrf", {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    });

    // Find the corresponding Form
    const formPromises = [...this.forms].map(async (form) => {
      if (form.classList.contains("freeform-form")) {
        const formHandle = form.dataset.handle;
        const { data: response } = await axios.get(
          `/dynamic/freeform?form=${formHandle}`,
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );

        form.querySelector("input[name=formHash]").value = response.hash;
        const honeypot = response.honeypot;
        const honeypotInput = form.querySelector(
          'input[name^="freeform_form_handle"]'
        );
        honeypotInput.setAttribute("id", honeypot.name);
        honeypotInput.setAttribute("name", honeypot.name);
        honeypotInput.value = honeypot.hash;
      }

      if (form.querySelector("input[name=" + csrfData.name + "]")) {
        form.querySelector("input[name=" + csrfData.name + "]").value =
          csrfData.value;
      }
    });

    await Promise.all(formPromises);
  };
}

export default Forms;
