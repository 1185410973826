import { disablePageScroll, enablePageScroll } from "scroll-lock";
import axios from "axios";

class Nav {
  constructor(nav, topBar, overlay) {
    this.nav = nav;
    this.topBar = topBar;
    this.button = this.topBar.querySelector(".menu-button");
    this.overlay = overlay;
    this.mobileLogo = this.topBar.querySelector(".logo-link");
  }

  init = () => {
    this.button.addEventListener("click", this.handleClick);
    this.overlay.addEventListener("click", this.close);
    window.addEventListener("resize", this.close);

    this.addBottomNav();
  };

  handleClick = () => {
    if (this.nav.classList.contains("is-open")) {
      this.close();
    } else {
      this.open();
    }
  };

  open = () => {
    this.nav.classList.add("is-open");
    this.topBar.classList.remove("bg-white");
    this.button.classList.add("open");
    this.overlay.classList.add("!bg-opacity-70");
    this.mobileLogo.classList.add("opacity-0", "pointer-events-none");
    this.overlay.classList.remove("pointer-events-none");

    if (window.matchMedia("(min-width: 640px)").matches) {
      this.nav.classList.add("!translate-x-0");
    } else {
      this.nav.classList.add("!translate-y-0");
    }

    disablePageScroll(this.nav);
  };

  close = () => {
    this.nav.classList.remove("is-open");
    this.topBar.classList.add("bg-white");
    this.button.classList.remove("open");
    this.overlay.classList.remove("!bg-opacity-70");
    this.mobileLogo.classList.remove("opacity-0", "pointer-events-none");
    this.overlay.classList.add("pointer-events-none");

    if (window.matchMedia("(min-width: 640px)").matches) {
      this.nav.classList.remove("!translate-x-0");
    } else {
      this.nav.classList.remove("!translate-y-0");
    }
    enablePageScroll(this.nav);
  };

  addBottomNav = async () => {
    const { data } = await axios.get("/dynamic/bottom-nav", {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
      responseType: "document",
    });
    if (data) {
      this.nav.insertAdjacentHTML(
        "beforeend",
        data.querySelector(".menu-bottom").outerHTML
      );
    }
  };
}

export default Nav;
