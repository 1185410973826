import "../css/app.pcss";
import router from "./router";
import Nav from "./components/Nav";
import SignIn from "./components/SignIn";
import Modal from "./components/Modal";
import Forms from "./components/Forms";

const announcementHandler = () => {
  const announcement = document.querySelector("#announcement");
  // get announcement cookie
  const announcementCookie = getCookie("announcement");

  if (announcement && !announcementCookie) {
    const modal = new Modal(announcement);
    modal.init();
    modal.open();

    // set announcement cookie
    setCookie("announcement", "true", 1);
  }
};

const getCookie = (name) => {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");
  if (parts.length == 2) return parts.pop().split(";").shift();
};

const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = name + "=" + value + "; expires=" + date.toGMTString();
};

window.addEventListener("load", () => {
  router();

  const nav = new Nav(
    document.querySelector(".sidebar"),
    document.querySelector(".mobile-top-bar"),
    document.querySelector(".menu-overlay")
  );

  nav.init();

  const signIn = new SignIn(document.querySelector("#sign-in form"));
  signIn.init();

  const singInModal = new Modal(document.querySelector("#sign-in"));
  singInModal.init();

  const checkLogin = () => {
    const hash = window.location.hash;

    if (hash === "#login") {
      singInModal.open();
    }
  };

  window.addEventListener("hashchange", checkLogin);

  checkLogin();

  const forms = new Forms();
  forms.init();

  announcementHandler();
});
